import * as React from "react";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image";
import { Button } from "../components/button";

interface IndexPageProps {
  data: {
    site: {
      siteMetadata: {
        title: string;
      };
    };
    file: {
      childImageSharp: {
        fluid: any;
      };
    };
  };
}

export default class extends React.Component<IndexPageProps, {}> {
  constructor(props: IndexPageProps, context: any) {
    super(props, context);
  }
  public render() {
    return (
      <div style={{ paddingBottom: 40 }}>
        <Img
          fluid={this.props.data.file.childImageSharp.fluid}
          alt=""
          style={{ maxHeight: 500, backgroundColor: "#D3D9DD", bottom: 0 }}
        />
        <div
          style={{
            margin: "0 auto",
            maxWidth: 1080,
            paddingTop: 0,
            textAlign: "center",
            paddingRight: "20px",
            paddingLeft: "20px",
          }}
        >
          <h3
            style={{
              fontFamily: `'Cinzel', serif`,
              fontSize: "24pt",
              marginTop: 54,
            }}
          >
            Saturday 2nd October 2021
          </h3>
          <h3
            style={{
              fontFamily: `'Cinzel', serif`,
              fontSize: "16pt",
              marginTop: 54,
            }}
          >
            Coulsdon manor hotel, Coulsdon Ct Rd, Coulsdon CR5 2LL
          </h3>
          <iframe
            className="location-map"
            height="400"
            style={{ border: 0 }}
            src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyCY0ZD8EQugXWLpEzJRBSYafh52ZtU68Dk&q=Coulsdon+Manor+Hotel,London+UK`}
          ></iframe>
          <h3
            style={{
              fontFamily: "Raleway",
              fontSize: "16pt",
              maxWidth: "900px",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: 20,
              fontWeight: 200,
            }}
          >
            We can’t wait to have you with us on our special day! Please send
            your RSVP below, and please check out the rest of our site for event
            details, food options and more!
          </h3>
          <Link to="/rsvp">
            <Button>RSVP</Button>
          </Link>
        </div>
      </div>
    );
  }
}

export const pageQuery = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        title
      }
    }
    file: file(relativePath: { eq: "aisle.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
