import * as React from "react";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
}
export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, ...props }, ref) => {
    return (
      <button
        {...props}
        ref={ref}
        className="clickable-link"
        style={{
          paddingTop: 6,
          paddingBottom: 6,
          paddingLeft: 30,
          paddingRight: 30,
          borderRadius: 10,
          borderWidth: 0,
          fontFamily: "Raleway",
          backgroundColor: "#D896AEB5",
          boxShadow: "4px 4px 16px #aaaaaaB5",
          marginBottom: 10,
          marginTop: 20,
        }}
      >
        {children}
      </button>
    );
  }
);
